<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">
      <b-card no-body>
        <div class="p-1 d-flex justify-content-between">
          <h4>{{ $t("Questionnaire Answer View") }}</h4>
          <feather-icon
            icon="PrinterIcon"
            size="25"
            class="cursor-pointer"
            @click="printer()"
          />
        </div>
        <hr class="mb-0 mt-0" />
        <b-card-body ref="printContent" class="printable-content">
          <div v-for="(item, index) in questions" :key="index" class="mb-2">
            <h4>{{ index + 1 }} {{ item.question }}</h4>
            {{
              item.type !== "many_options"
                ? item.answerQuestion
                : item.answerOption.option
            }}
            <span
              class="cursor-pointer"
              v-if="item.answerFile"
              @click="clickFile(item.answerFile)"
            >
              <feather-icon
                v-if="item.type === 'file'"
                icon="FileIcon"
                size="25"
              />
              {{ item.answerFile.split("/").at(-1) }}
            </span>

            <b-img
              v-if="item.type === 'image'"
              ref="previewEl"
              rounded
              :src="item.answerImage"
              height="100"
              @click="clickFile(item.answerImage)"
              class="cursor-pointer"
            />
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BOverlay,
  BImg,
} from "bootstrap-vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import { ref } from '@vue/composition-api'
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import storeModule from "@/store/services/eCommerce/questionnaire";

const STORE_MODULE_NAME = "questionnaire";
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    // eslint-disable-next-line vue/no-unused-components
    BCardTitle,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BImg,

    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      name: "",
      detail: "",
      questions: [
        // {
        //   question: '',
        //   type: 'short_message',
        //   listOption: [
        //     {
        //       option: '',
        //     },
        //   ],
        //   required: false,
        // },
      ],
      required,
    };
  },
  computed: {
    OptionQuestion() {
      return [
        { name: this.$t("Short message"), value: "short_message" },
        { name: this.$t("Paragraph"), value: "paragraph" },
        { name: this.$t("Many options"), value: "many_options" },
        { name: this.$t("Link"), value: "link" },
        { name: this.$t("Tel"), value: "tel" },
      ];
    },
    // eslint-disable-next-line vue/return-in-computed-property
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME))
      store.registerModule(STORE_MODULE_NAME, storeModule);

    if (this.$route.params.id !== "add") {
      const obj = {
        questionnaireId: this.$route.params.questionnaireId,
        id: this.$route.params.id,
      };

      this.show = true;
      store
        .dispatch(`${STORE_MODULE_NAME}/viewLogs`, obj)
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
          this.show = false;
          const respData = result.data.data;
          this.selectedData(respData);
          // console.log('fetch Success : ', result)
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch((error) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "bottom-right",
            props: {
              title: this.$t("Error"),
              icon: "ErrorIcon",
              variant: "danger",
              text: this.$t(error.response.data.message),
            },
          });
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        });
    } else {
      this.newQuestion();
    }
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME))
      store.unregisterModule(STORE_MODULE_NAME);
  },
  setup() {
    return { STORE_MODULE_NAME };
  },
  methods: {
    clickFile(url) {
      window.open(url);
    },
    printer() {
      const { printContent } = this.$refs;
      if (printContent) {
        const newWindow = window.open(
          "",
          "popupWindow",
          "width=620,height=900,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes"
        );
        newWindow.document.write(`
          <html>
            <head>
              <title>พิมพ์ข้อมูล</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 20px;
                }
              </style>
            </head>
            <body>
              ${printContent.innerHTML}
            </body>
          </html>
        `);
        newWindow.document.close();
        newWindow.focus();
        newWindow.print();
        newWindow.close();
      } else {
        console.error("printContent ไม่พบใน DOM");
      }
    },
    findAnswerOption(data, list) {
      if (list.option === data.option) {
        return true;
      }
      return false;
    },
    showMessageType(data) {
      const index = this.OptionQuestion.findIndex((e) => e.value === data);
      return index > -1
        ? this.OptionQuestion[index].name
        : this.$t("Not match");
    },
    addOption(index) {
      this.questions[index].listOption.push({
        option: "",
      });
    },
    deleteOption(index, indexList) {
      this.questions[index].listOption.splice(indexList, 1);
    },
    newQuestion() {
      this.questions.push({
        question: "",
        type: "short_message",
        listOption: [
          {
            option: "",
          },
        ],
        required: false,
      });
    },
    deleteQuestion(index) {
      this.questions.splice(index, 1);
    },
    copyQuestion(item) {
      this.questions.push({
        question: item.question,
        type: item.type,
        listOption: item.listOption,
        required: item.required,
      });
    },
    changeSwitch(event, index) {
      this.questions[index].required = event;
    },
    selectedData(data) {
      this.dataId = data._id;
      this.name = data.name;
      this.detail = data.detail;
      this.questions = data.questions;
    },
    validationForm() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to save")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "success",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$refs.formData.validate().then(async (success) => {
              if (success) {
                const listCheck = [];
                for (let index = 0; index < this.questions.length; index += 1) {
                  // eslint-disable-next-line no-await-in-loop
                  const successPass = await this.$refs[`formData${index}`][0]
                    .validate()
                    .then((val) => val);
                  listCheck.push(successPass);
                }
                if (listCheck.every((status) => status === true)) {
                  const obj = {
                    id: this.dataId,
                    name: this.name,
                    detail: this.detail,
                    questions: this.questions,
                  };
                  if (obj.id !== null) {
                    this.show = true;

                    store
                      .dispatch(`${STORE_MODULE_NAME}/update`, obj)
                      .then((result) => {
                        console.log("fetch Success : ", result);
                        this.show = false;
                        // this.$router.push({ path: '/eCommerce/queue/questionnaire' })
                        this.$router.push({
                          path: "/e-commerce/questionnaire",
                        });
                        this.$toast({
                          component: ToastificationContent,
                          position: "bottom-right",
                          props: {
                            title: this.$t("Success"),
                            icon: "CheckIcon",
                            variant: "success",
                            text: this.$t("Success"),
                          },
                        });
                      })
                      .catch((error) => {
                        console.log("fetchUsers Error : ", error);
                        this.show = false;

                        this.$toast({
                          component: ToastificationContent,
                          position: "bottom-right",
                          props: {
                            title: this.$t("Error"),
                            icon: "ErrorIcon",
                            variant: "danger",
                            text: this.$t(error.response.data.message),
                          },
                        });
                      });
                  } else {
                    this.show = true;

                    store
                      .dispatch(`${STORE_MODULE_NAME}/add`, obj)
                      .then((result) => {
                        console.log("fetch Success : ", result);
                        this.show = false;

                        // this.$router.push({ path: '/eCommerce/queue/questionnaire' })
                        this.$router.push({
                          path: "/e-commerce/questionnaire",
                        });

                        this.$toast({
                          component: ToastificationContent,
                          position: "bottom-right",
                          props: {
                            title: this.$t("Success"),
                            icon: "CheckIcon",
                            variant: "success",
                            text: this.$t("Success"),
                          },
                        });
                      })
                      .catch((error) => {
                        console.log("fetchUsers Error : ", error);
                        this.show = false;

                        this.$toast({
                          component: ToastificationContent,
                          position: "bottom-right",
                          props: {
                            title: this.$t("Error"),
                            icon: "ErrorIcon",
                            variant: "danger",
                            text: this.$t(error.response.data.message),
                          },
                        });
                      });
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "bottom-right",
                    props: {
                      title: this.$t("Error"),
                      icon: "ErrorIcon",
                      variant: "danger",
                      text: this.$t("Error Validator"),
                    },
                  });
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t("Error Validator"),
                  },
                });
              }
            });
          }
        });
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(`${this.$t("Do you want to cancel")}?`, {
          title: this.$t("Please Confirm"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            // this.$router.push({ path: '/eCommerce/queue/questionnaire' })
            this.$router.push({ path: "/eCommerce/questionnaire" });
          }
        });
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        }
      );
    },
  },
};
</script>
<style>
/* ซ่อนทุกส่วนของหน้าเว็บที่ไม่ต้องการพิมพ์ */
@media print {
  body * {
    display: none !important;
  }

  /* แสดงเฉพาะเนื้อหาที่ต้องการพิมพ์ */
  .print-only {
    display: block !important;
  }

  /* เพิ่มสไตล์สำหรับการพิมพ์ */
  .print-only {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
